<template>
    <div class="section">
        <PageHeader title="Dashboard" />
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card__grid">
                        <div class="card" v-for="item in cards" :key="item.index">
                            <b-icon :icon="item.icon" variant="primary"></b-icon>
                            <h3>{{ item.title }}</h3>
                            <router-link :to="item.path || ''" :href="item.link || ''" :target="item.link ? '_blank' : ''" class="btn btn-secondary">View</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
    components: { PageHeader },
    data() {
        return {
            cards: [
                {
                    title: "Website",
                    link: "https://awaketomorrow.org",
                    icon: "window-sidebar"
                },
                {
                    title: "Shop",
                    link: "https://shop.awaketomorrow.org",
                    icon: "cart3"
                },
                {
                    title: "Linktree",
                    link: "https://awktmrw.org",
                    icon: "link45deg"
                },
                {
                    title: "Brand Guidelines",
                    link: "https://www.lingoapp.com/109515/k/Awake-Tomorrow-dr6Yvy",
                    icon: "journal-richtext"
                },
                {
                    title: "QR Codes",
                    path: "/qr",
                    icon: "upc-scan"
                },
                {
                    title: "Campaign Photos",
                    link: "https://photos.app.goo.gl/Qh37Yk84YZH5j6V78",
                    icon: "images"
                },
                {
                    title: "Milestones",
                    path: "/milestones",
                    icon: "award"
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.card__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
}

.card {
    text-align: center;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card svg {
    font-size: 4.5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
    .card__grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
</style>