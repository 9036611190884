<template>
    <div class="sub-header">
        <b-navbar variant="secondary" type="dark">
            <div class="container">
                <b-navbar-nav>
                    <b-nav-text v-if="title">{{ title }}</b-nav-text>
                    <b-nav-item v-for="item in nav" :key="item.name" :to="item.path">{{
                        item.name
                    }}</b-nav-item>
                </b-navbar-nav>
            </div>
        </b-navbar>
    </div>
</template> 
  
<script>
export default {
    props: {
        title: String,
        nav: Array,
    },
};
</script>
  
<style lang="scss" scoped>
.navbar-nav {
    gap: 1rem;
}
</style>