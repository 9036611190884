<template>
    <div class="section milestones">
        <PageHeader title="Update Milestones" />
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <form @submit.prevent="submitForm">
                        <b-form-group v-for="item, name in milestones" :key="item.index" :label="name">
                            <b-form-input v-model="milestones[name]"></b-form-input>
                        </b-form-group>
                        <div v-if="submitStatus">Milestones updated.</div>
                        <button type="submit" class="btn btn-primary mt-3" value="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase.js"
import PageHeader from "../components/PageHeader.vue"
export default {
    name: "MileStones",
    components: {
        PageHeader
    },
    data() {
        return {
            milestones: {},
            submitStatus: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const docRef = doc(db, "milestones", "default");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                this.milestones = docSnap.data()
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        },
        submitForm() {
            setDoc(doc(db, "milestones", 'default'), this.milestones).then();
            this.submitStatus = true
        }
    }
}
</script>