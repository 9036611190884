<template>
  <div class="header__wrapper">
    <b-navbar toggleable="md" type="dark" variant="dark">
      <div class="container">
        <b-navbar-brand href="/"><img src="../assets/at_arc_orange.png"
            class="header__logo img-fluid"></b-navbar-brand>

        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="nav__wrapper">
            <b-nav-item to="/qr">QR Codes</b-nav-item>
          </b-navbar-nav>

        </b-collapse> -->
      </div>
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "DefaultHeader"
}
</script>
<style lang="scss" scoped>
.header__logo {
  max-width: 50px;
}
.sub__menu a{
  color: #fff
}
@media screen and (max-width: 767px) {

}
</style>