import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import QRWrapper from '../views/QRWrapper.vue'
import MileStones from '../views/MileStones.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/milestones',
    component: MileStones,
  },
  {
    path: '/qr',
    component: QRWrapper,
    children: [
      {
        path: '',
        component: () => import('../views/QRListing.vue'),
        
      },
      {
        path: 'create',
        component: () => import('../views/QRCreate.vue'),
      },
      {
        path: 'details/:path',
        component: () => import('../views/QRDetails.vue'),
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
