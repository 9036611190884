<template>
    <div class="container mb-5">
      <div class="title">
        <div class="heading">
        <h1>{{ title }}</h1>
        </div>
      </div>
        <hr />
      </div>
    </template>
    <script>
    export default {
      props: {
        title: String,
      },
    };
    </script>
    <style scoped>
    
    </style>