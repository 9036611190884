<template>
  <div>
    <SubMenu :nav="nav" />
    <router-view />
  </div>
</template> 
  
<script>
import SubMenu from "../components/SubMenu.vue";
export default {
  components: {
    SubMenu
  },
  data() {
    return {
      title: "QR Code Generator",
      nav: [
        {
          name: "All QR Codes",
          path: "/qr",
        },
        {
          name: "Create New",
          path: "/qr/create",
        },
      ],
    };
  },
};
</script>
  
<style scoped>
.sub__menu a {
  color: #fff;
}
</style>